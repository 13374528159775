import {
  Button,
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { ReactComponent as CartMedia } from '../../assets/icons/carrinho-compras-roxo.svg'
import IconeExcluir from '../../assets/icons/icone-excluir-produto-carrinho.png'
import RepImagesContext from '../../contexts/RepImages'
import { NumberButtonsInput } from '../Inputs'
import { isWebpSupported } from 'react-image-webp/dist/utils'
import noProductImage from '../../assets/img/noImage.png'
import handleOnImageError from '../../utils/handleOnImageError'
import CartContext from '../../contexts/Cart'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    borderRadius: 14,
  },
  table: {
    minWidth: 650,
  },
  button: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    padding: '6px 2px',
    minWidth: 53,
    height: 53,
    '&:hover': {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText,
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: 43,
      height: 43,
    },
  },
  cell: {
    borderBottom: 'none',
    padding: theme.spacing(3, 1),
  },
  qtyCart: {
    color: theme.palette.primary.main,
    fontSize: '1.3rem',
  },
  title: {
    fontSize: '1.3rem',
    color: theme.palette.text.primary,
    lineHeight: 1,
  },
  subtitle: {
    fontSize: '1rem',
    color: theme.palette.text.secondary,
    lineHeight: 1.5,
  },
  money: {
    fontSize: '2rem',
    marginLeft: 3,
    marginRight: 3,
    lineHeight: 1.1,
    color: '#000',
  },
  sub: {
    fontSize: '0.9rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.7rem',
    },
  },
  sob: {
    fontSize: '1rem',
    verticalAlign: 'top',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.85rem',
    },
  },
  box: {
    padding: '2px 7px',
    backgroundColor: theme.palette.primary.main,
    borderRadius: 9,
    height: 53,
    width: 134,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: 43,
    },
  },
  label: {
    color: theme.palette.secondary.main,
    //marginLeft: theme.spacing(1)
  },
  valorUnidade: {
    fontSize: '2rem',
    lineHeight: 1,
    color: theme.palette.primary.contrastText,
    textAlign: 'center',
    marginTop: '-2px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.6rem',
      marginTop: '-4px',
    },
  },
  containerMobile: {
    padding: theme.spacing(3, 2),
    borderRadius: 14,
  },
  valueMobile: {
    fontSize: '2rem',
  },
  tagSobEncomenda: {
    backgroundColor: theme.palette.primary.main,
    color: '#FFF',
    textAlign: 'center',
    borderRadius: '10px',
    width: 'fit-content',
    padding: '0 15px',
    margin: '5px 0',
  },
}))

const ListItens = ({ onChange, onDelete, ...props }) => {
  const classes = useStyles()
  const dataCart = useContext(CartContext);

  //const [itens, setItens] = useState([]);
  const [itens, setItens] = useState([])
  const [qty, setQty] = useState(dataCart.qty)

  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('sm'))

  const repImgs = useContext(RepImagesContext)

  function updateState(data) {
    data.itens.forEach((item) => {
      item.total_item = parseFloat(item.qtd * item.valor_padrao)
        .toLocaleString('pt-br', { minimumFractionDigits: 2 })
        .split(',')
        .slice(0)
      item.unitario_item = parseFloat(item.valor_padrao)
        .toLocaleString('pt-br', { minimumFractionDigits: 2 })
        .split(',')
        .slice(0)
    })
    setItens(data.itens)
    setQty(data.qty)
  }

  useEffect(() => {
    
    dataCart.subscribe(updateState)

    return function cleanup() {
      dataCart.unsubscribe(updateState)
    }
  }, [dataCart.itens])

  useEffect(() => {
    async function callCart() {
      await dataCart.refresh();
      updateState(dataCart);
    }

    callCart()
  }, []);

  const _handleQtd = (value, index) => {
    onChange(value, index)
  }

  const handleDelete = (value, index) => {
    onDelete(value, index)
  }

  return (
    <Fragment>
      {mobile && (
        <Grid container className={classes.containerMobile} component={Paper} elevation={2}>
          <Grid
            item
            xs={12}
            spacing={2}
            style={{ paddingBottom: 16, borderBottom: '1px solid rgba(224, 224, 224, 1)' }}
          >
            <CartMedia style={{ width: 32, height: 32, marginLeft: 16 }} />{' '}
            <span className={classes.qtyCart}>({qty})</span>
          </Grid>
          {itens.map((item, index) => (
            <>
              <Grid
                item
                xs={12}
                container
                spacing={3}
                style={{ paddingBottom: 16, marginTop: 16, borderBottom: '1px solid rgba(224, 224, 224, 1)' }}
              >
                <Grid item xs={5}>
                  <NavLink to={`/produto/${item.product_id}`}>
                    <img
                      /* src={
                        isWebpSupported() && item.media?.[0]?.full_webp_url
                          ? item.media?.[0]?.full_webp_url
                          : item.media?.[0]?.full_url
                      } */
                      src={item.image}
                      alt={item.nome}
                      width="92"
                      height="92"
                      onError={(e) => handleOnImageError(e, noProductImage)}
                    />
                  </NavLink>
                </Grid>
                <Grid item xs={7}>
                  <Typography variant="h4" className={classes.title}>
                    {item.nome}
                  </Typography>
                  <Typography variant="subtitle1" className={classes.tagSobEncomenda}>
                    {item.variacao?.sob_encomenda ? 'Sob Encomenda' : null}
                  </Typography>
                  <Typography variant="subtitle1" className={classes.subtitle}>
                    {item.variacao?.sku}
                  </Typography>
                  {item.variacao?.atributos &&
                    item.variacao?.atributos.map((attr) => (
                      <Typography variant="subtitle1" className={classes.subtitle}>
                        <span style={{ color: '#6d6d6d' }}>{attr.atributo} : </span>
                        {attr.valor}
                      </Typography>
                    ))}
                  <div className={classes.valueMobile}>
                    {!item.total_item && <Skeleton variant="text" width={120} />}
                    {item.total_item && (
                      <>
                        <span className={classes.sub}>R$ </span>
                        {item.total_item[0]}
                        <span className={classes.sob}>,{item.total_item[1]}</span>
                      </>
                    )}
                  </div>
                </Grid>

                <Grid item xs={12} container spacing={1} justify="space-between" style={{ marginTop: 16 }}>
                  <Grid item xs={5}>
                    <div className={classes.box}>
                      <Typography variant="caption" className={classes.label} component="div">
                        Unidade
                      </Typography>
                      <Typography variant="subtitle2" className={classes.valorUnidade}>
                        {!item.unitario_item && <Skeleton variant="text" width={120} />}
                        {item.unitario_item && (
                          <>
                            <span className={classes.sub}>R$ </span>
                            {item.unitario_item[0]}
                            <span className={classes.sob}>,{item.unitario_item[1]}</span>
                          </>
                        )}
                      </Typography>
                    </div>
                  </Grid>

                  <Grid item xs={5}>
                    <NumberButtonsInput
                      index={index}
                      minValue={item.quantidade_minima || 500}
                      valueDefault={item.qtd || 500}
                      onChange={_handleQtd}
                      value={item.qtd || 500}
                      scheme="cart"
                    />
                  </Grid>

                  <Grid item xs={2}>
                    <Button variant="contained" className={classes.button} onClick={() => handleDelete(item, index)}>
                      {/* <Icon className="far fa-trash-alt" style={{ fontSize: 30 }} /> */}
                      <img src={IconeExcluir} width={43} height={43} />
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </>
          ))}
        </Grid>
      )}
      {!mobile && (
        <TableContainer className={classes.root} component={Paper} elevation={3}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell variant="head" colSpan={5}>
                  <CartMedia style={{ width: 32, height: 32 }} /> <span className={classes.qtyCart}>({qty})</span>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {itens.map((item, index) => (
                <TableRow key={index}>
                  <TableCell padding="checkbox" className={classes.cell}>
                    <NavLink to={`/produto/${item.product_id}`}>
                      <img
                        /* src={
                          isWebpSupported() && item.media?.[0]?.full_webp_url
                            ? item.media?.[0]?.full_webp_url
                            : item.media?.[0]?.full_url
                        } */
                        src={item.image}
                        alt={item.nome}
                        width="64"
                        height="64"
                        onError={(e) => handleOnImageError(e, noProductImage)}
                      />
                    </NavLink>
                  </TableCell>
                  <TableCell className={classes.cell}>
                    <Typography variant="h4" className={classes.title}>
                      {item.nome}
                    </Typography>
                    <Typography variant="subtitle1" className={classes.tagSobEncomenda}>
                      {item.variacao?.sob_encomenda ? 'Sob Encomenda' : null}
                    </Typography>
                    <Typography variant="subtitle1" className={classes.subtitle}>
                      {item.variacao?.sku}
                    </Typography>
                    {item.variacao?.atributos &&
                      item.variacao?.atributos.map((attr) => (
                        <Typography variant="subtitle1" className={classes.subtitle}>
                          <span style={{ color: '#6d6d6d' }}>{attr.atributo} : </span>
                          {attr.valor}
                        </Typography>
                      ))}
                  </TableCell>
                  <TableCell style={{ width: 150 }} size="small" className={classes.cell}>
                    <div className={classes.box}>
                      <Typography variant="caption" className={classes.label}>
                        Unidade
                      </Typography>
                      <Typography variant="subtitle2" className={classes.valorUnidade}>
                        {!item.unitario_item && <Skeleton variant="text" width={120} />}
                        {item.unitario_item && (
                          <>
                            <span className={classes.sub}>R$ </span>
                            {item.unitario_item[0]}
                            <span className={classes.sob}>,{item.unitario_item[1]}</span>
                          </>
                        )}
                      </Typography>
                    </div>
                  </TableCell>
                  <TableCell style={{ width: 150 }} size="small" className={classes.cell}>
                    <NumberButtonsInput
                      index={index}
                      minValue={item.quantidade_minima || 500}
                      valueDefault={item.qtd || 500}
                      onChange={_handleQtd}
                      value={item.qtd || 500}
                      scheme="cart"
                    />
                  </TableCell>
                  <TableCell size="small" style={{ width: 70 }} className={classes.cell}>
                    <Button variant="contained" className={classes.button} onClick={() => handleDelete(item, index)}>
                      {/* <Icon className="far fa-trash-alt" style={{ fontSize: 30 }} /> */}
                      <img src={IconeExcluir} width={53} height={53} />
                    </Button>
                  </TableCell>
                  <TableCell style={{ width: 140 }} className={`${classes.money} ${classes.cell}`}>
                    {!item.total_item && <Skeleton variant="text" width={120} />}
                    {item.total_item && (
                      <>
                        <span className={classes.sub}>R$</span>
                        {item.total_item[0]}
                        <span className={classes.sob}>,{item.total_item[1]}</span>
                      </>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Fragment>
  )
}

export default ListItens
